import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import Masonry from 'masonry-layout'

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})

export class AboutComponent implements OnInit, AfterViewInit {
    focus: any;
    focus1: any;

    constructor(public element: ElementRef) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.initMasonry();
    }

    initMasonry() {
        const grid = document.querySelector('.masonry');
        const masonry = new Masonry(grid, {
            itemSelector: '.masonry-item',
            horizontalOrder: true,
            gutter: 20
        });

        const grid2 = document.querySelector('.masonry2');
        const masonry2 = new Masonry(grid2, {
            itemSelector: '.masonry-item',
            horizontalOrder: true,
            gutter: 20
        });


    }

    mailto() {
        window.location.href = 'mailto:elke.umbach@contrast-und-perspective.de';
    }

}
