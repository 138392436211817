import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-landing',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})

export class ServicesComponent implements OnInit {
    focus: any;
    focus1: any;

    constructor() {
    }

    ngOnInit() {
    }

    mailto() {
        window.location.href = 'mailto:elke.umbach@contrast-und-perspective.de';
    }

}
